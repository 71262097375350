body {
    position: relative;
}

a {
    text-decoration: none;
}

.modal-open {
    overflow: hidden
}

.modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

.fade {
    opacity: 0;
    transition: opacity .15s linear;
}

.fade.in {
    opacity: 1;
}

.modal.fade .modal-dialog {
    transform: scale(0.9);
    opacity: 0;
    transition: all 120ms cubic-bezier(.45,1.8,.5,.75) 60ms;
}

.modal.fade.in .modal-dialog {
    transform: scale(1);
    opacity: 1;
}

.modal.in .modal-dialog {
    transform: translate(0,0);
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
}

.modal-content {
    max-width: 600px;
    position: relative;
    background-color: #fbfcfc;
    border: 1px solid transparent;
    border-radius: 6px;
    -webkit-box-shadow: 0 4px 18px rgba(0, 0, 0, 0.08);
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.08);
    background-clip: padding-box;
    outline: 0;
    margin: 100px auto 100px auto;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #273236;
}

.modal-backdrop.fade {
    opacity: 0;
    transition: all 50ms linear;
}

.modal-backdrop.in {
    opacity: .3;
}

.modal-header {
    padding: 16px 24px 12px;
    border-bottom: 1px solid transparent
}

.modal-header .close {
    margin-top: -2px;
}

.modal-title {
    margin: 0;
    line-height: 1.42857143;
}

.modal-body {
    position: relative;
    padding: 24px;
}

.modal-footer {
    padding: 24px;
    text-align: right;
    border-top: 1px solid transparent;
}

.modal-footer .btn+.btn {
    margin-left: 5px;
    margin-bottom: 0;
}

.modal-footer .btn-group .btn+.btn {
    margin-left: -1px;
}

.modal-footer .btn-block+.btn-block {
    margin-left: 0;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

button.close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
}

.close {
    font-weight: 400;
    text-shadow: none;
    opacity: .35;
}

.close {
    float: right;
    font-size: 22.5px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
}

.error-alert {
    padding: 18px 20px;
    font-family: var(--p-font-family);
    font-size: var(--p-font-size);
    color: #fb6424;
    background-color: #fbf2f2;
    border-radius: var(--primary-button-border-radius);
}

.error-reason {
    font-size: 13px;
    font-family: var(--p-font-family);
    margin-top: 5px;

    &.text-danger {
        color: #fb6424;
    }
}
